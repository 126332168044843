// @font-face {
//   font-family: 'FTFont';
//   src: url('./assets/fonts/NT-Book.woff2') format('woff2'), url('./assets/fonts/NT-Book.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: auto;
// }

// @font-face {
//   font-family: 'FTFont';
//   src: url('./assets/fonts/NT-Bold.woff2') format('woff2'), url('./assets/fonts/NT-Bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
//   font-display: auto;
// }

// @font-face {
//   font-family: 'FTFont';
//   src: url('./assets/fonts/NDT.woff2') format('woff2'), url('./assets/fonts/NDT.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
//   font-display: auto;
// }

// @font-face {
//   font-family: 'FTFontCond';
//   src: url('./assets/fonts/NFC-BoldAlt.woff2') format('woff2'), url('./assets/fonts/NFC-BoldAlt.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: auto;
// }

// --> NOLA --> FATONI <-- KADAVAR <-- JEREMIAS <- FIBEL SKIN <- BLUMFELD SKIN

// @import url('https://use.typekit.net/yza7sme.css');

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;700&display=swap');

$ftborder: 0.1rem solid rgba($basetext, 1);

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: #000;
}

#root {
  background-color: #fff;
}

body.ww-page {
  h1 {
    color: $white;
    font-weight: 200 !important;
    @include font-size(5rem);
    letter-spacing: -3px;
  }
  h2 {
    color: $white;
    font-weight: 700 !important;
  }
}

body.ww-page,
body.ticket-listing-page {
  display: block;
  position: relative;
  //background-image: url('./assets/images/background.jpg');
  background-color: $primary;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10;
  #root {
    background-color: transparent;
  }
}

.card.bg-light {
  background-color: transparent !important;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
}

.container {
  // padding-left: 50px;
  // padding-right: 50px;
}

.invisible {
  display: none;
}

.btn,
h1,
h2,
h3,
h4,
h5,
.upline,
legend {
  //text-transform: uppercase;
}

h1 {
  font-weight: 700;
}

// NAV
#header {
  @extend .sticky-top;
  transition: transform 0.15s ease-out;
  border-bottom: 0.1rem solid rgba($basetext, 0.08);
  background-color: $black !important;
  color: $white;
  z-index: 2;

  .navbar {
    @extend .container;

    max-width: 1140px;

    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    @include media-breakpoint-down(sm) {
      padding-top: 0.6rem !important;
      padding-bottom: 0.6rem !important;
    }
  }

  .bg-light {
    background-color: $black !important;
  }
}
body[data-scrolled] {
  #header {
    transform: translateY(-100%);
  }
}
body[data-scrollingup],
body[data-menuopen][data-scrolled] {
  #header {
    transform: translateY(0%);
  }
}

#header .navbar {
  .navbar-brand {
    padding: 0;
    img {
      max-width: 130px;
      padding-left: 0;
      //height: 3rem;

      &:hover,
      &:focus {
        // filter: opacity(0.66);
      }
    }
    @include media-breakpoint-down(sm) {
      img {
        // max-width: 75px;
      }
      // margin: 0;
      // height: 3.5rem;
      // max-height: 3.5rem;
    }
  }
}

#header {
  // big screens
  .main-menu {
    // hide the main menu hamburger
    display: none !important;
  }
  .navbar .secondary-menu {
    //justify-content: space-between;
    padding-left: 1.5rem;
    .nav-item.last a {
      padding-right: 0;
    }

    .navbar-nav {
      color: $basetext;
      font-weight: 500;
      font-size: large;
    }
  }

  // small screens
  @include media-breakpoint-down(sm) {
    .navbar-brand-container {
      flex-grow: 1;
    }

    .main-menu {
      display: flex !important;
    }

    .navbar .secondary-menu {
      justify-content: flex-end !important;
      padding-left: 0;

      .menu {
        // hide category links
        display: none;
      }
      .navbar-nav {
        color: $primary;
        font-weight: 500;
        font-size: large;
      }
    }
    .flex-nowrap {
      justify-content: space-between;
    }
  }
}

// body[data-scrolled] {
//   #header:has(#cart-menu.open),
//   #header:has(#main-menu.open) {
//     opacity: 1 !important;
//     transform: translateY(0px);
//   }
// }

.navbar {
  .hamburger {
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    height: 1.25px;
  }

  .hamburger:hover {
    transform: scale(1.1) !important;
    transition: transform 0.15s ease, opacity 0.15s ease;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
  //margin-left: 20px;
  //min-width: 70px !important;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

#header .navbar .navbar-collapse .navbar-nav {
  .nav-item a {
    font-size: $font-size-base;
    font-weight: 700;
    //color: rgba($white, 0.75) !important;
    color: $basetext;

    &:hover,
    &.active {
      text-decoration: none;
      text-underline-offset: 0.3rem;
      color: $secondary !important;
    }
  }
}

#header .navbar .secondary-menu .navbar-nav {
  .nav-item a {
    font-size: $font-size-base;
    font-weight: 700;
    //color: rgba($white, 0.75) !important;
    color: $white;

    &:hover,
    &.active {
      text-decoration: none;
      text-underline-offset: 0.3rem;
      color: $secondary !important;
    }
  }
}

.offcanvas-collapse {
  background-color: rgba($bgcolor, 0.95);
  top: 106px;
  box-shadow: none;
  @include media-breakpoint-down(sm) {
    top: 79px;
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    //background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      //color: rgba(18, 18, 18, 0.75) !important;
    }
    a.active,
    a:hover {
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      //color: rgb(18, 18, 18) !important;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    //text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
  .nav-item:hover {
    background-color: transparent;
  }
}

.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// MAIN MENU
#main-menu {
  min-width: 90vw;
  height: 100vh;
  padding-top: 3rem;
  border-right: 0.1rem solid rgba($basetext, 0.08);
}

// OC CART
.offcanvas-collapse-right {
  width: min(90vw, 480px);
  height: 100vh !important;
  padding-top: 1rem;
  margin-left: 2rem;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  border-left: 0.1rem solid rgba($basetext, 0.08);
  h5 {
    font-size: $h5-font-size;
    // text-transform: uppercase;
    color: $basetext !important;
  }
}

// MAKI

.masonry.row {

}

.sushi-container {

}

.nigiri-container {
  margin-bottom: 0.5rem !important;
}

.maki.card {
  background-color: $black;
  overflow: hidden;
  //padding: 1rem;
  box-sizing: border-box;
  //border: $ftborder;
  color: $white;

  // border-radius: 10px;
  // background: #c00e34;
  // color: #FFF;
  // box-shadow: 2px 2px 15px 0px $black;

  // &:before,
  // &:after {
  //   content: '';
  //   position: absolute;
  //   left: 10px;
  //   right: 10px;
  //   height: 6px;

  // }

  // &:before {
  //   top: -5px;
  //   background: radial-gradient(circle, transparent, transparent 50%, #c00e34 50%, #c00e34 100% ) -7px -8px / 16px 16px repeat-x,
  // }

  // &:after {
  //   bottom: -5px;
  //   background: radial-gradient(circle, transparent, transparent 50%, #c00e34 50%, #c00e34 100% ) -7px -2px / 16px 16px repeat-x,
  // }

  .card-body {
    z-index: 1;
  }

  .card-title {
    //border-top: 2px dotted $black;
    // background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    // background-position: top;
    // background-size: 10px 3px;
    // background-repeat: repeat-x;
    @extend .text-truncate;
    max-width: 100%;

    padding: 1.25rem;

    display: block;
    text-align: left;
    padding-bottom: 0rem;

    .title {
      font-size: $h3-font-size;
      font-weight: 700 !important;
      //text-transform: uppercase;
      //padding-bottom: 0.8rem;
      //font-size: 13px;
      display: block;
      @extend .text-truncate;
      width: 100%;
    }

    .sub-title {
      font-size: smaller;
      @extend .text-truncate;
      display: block;
      width: 100%;
    }

    .price {
      font-weight: bold;
      display: inline-block;
    }

    .former-price {
      text-decoration: line-through;
      display: inline-block;
      //font-size: 13px;
      //line-height: 1.6rem;
      color: $secondary !important;
    }
  }

  .maki-img-container {
    overflow: hidden;
    background-color: $black;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card-header {
    color: $gray-700;
  }

  // // trying to control row placement
  // .card-title {
  //   display: grid;
  //   grid-template-columns: auto 1fr;
  //   grid-template-rows: auto auto;
  //   .date-container {
  //     display: none;
  //   }
  //   .title {
  //     grid-column-start: 1;
  //     grid-column-end: 3;
  //   }
  // }
  // TAGS

  .tag {
    background-color: $info;
    display: none;
    position: absolute;
    bottom: 10.5rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
  }

  &:hover {
    background-color: $secondary;
    .title {
      //text-decoration: underline;
    }
    .maki-img {
      transform: scale(1.03);
      transition: transform 0.5s ease;
      background-color: $secondary;
    }
    .maki-img-container {
      background-color: $secondary;
      overflow: hidden;
    }
  }
}

// PDP

.detail-page {
  .super-title {
    font-size: 0.7rem;
    letter-spacing: 1.3px;
    opacity: 1;
  }

  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
    span {
      font-size: 40px;
      letter-spacing: 0.6px;
      line-height: 52px;
      font-weight: 700;
      //text-transform: uppercase;
    }
  }
  .variant-chooser {
    text-align: left !important;
  }
  small.former-price {
    text-decoration: line-through;
    color: $secondary !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.3px;
    line-height: 24px;
    margin-right: 10px;
  }
  .price {
    display: block;
    text-align: left !important;
    font-size: 18px;
    letter-spacing: 1.3px;
    line-height: 27px;
    @extend .mt-3;
  }

  div[itemprop='description'] {
    p {
      //color: rgba(18, 18, 18, 0.75) !important;
      // font-size: 16px !important;
      // font-style: normal !important;
      // font-weight: 500 !important;
      // letter-spacing: 0.6px !important;
      // line-height: 28.8px !important;
    }
  }

  button.add-product {
    align-items: center;
    appearance: none;
    background-color: rgb(241, 239, 237);
    border: $basetext 1px solid;
    box-sizing: border-box;
    // display: flex;
    // justify-content: center;
    letter-spacing: 1px;
    // margin-bottom: 10px;
    // padding-bottom: 0px;
    // padding-left: 30px;
    // padding-right: 30px;
    // padding-top: 0px;
    text-transform: initial;
  }

  .carousel-indicators {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;

    button {
      width: 49%;
      border: 0px solid transparent;

      .product-thumb {
        height: 100% !important;
        width: 100% !important;
        transform: translate(-5px, -5px);
        margin: 0;
      }
    }
  }

  .carousel-inner {
    background-color: #fff;
  }

  // .variant-chooser-quantity {
  //   border: rgb(18, 18, 18) 1px solid;

  //   button {
  //     align-items: center;
  //     background-color: rgba(0, 0, 0, 0);
  //     color: rgb(18, 18, 18);
  //     display: flex;
  //     font-size: 18px;
  //     font-weight: 400;
  //     justify-content: center;
  //     margin-left: 1px;
  //     padding-bottom: 0px;
  //     padding-left: 0px;
  //     padding-right: 0px;
  //     padding-top: 0px;
  //   }
  // }

  @include media-breakpoint-down(md) {
    header {
      text-align: left !important;

      .super-title.text-muted,
      .product-header-title {
        text-align: left !important;
      }
      .product-header-title {
        font-weight: 700;
      }
    }
    .price,
    h2.product-title {
      text-align: left !important;
    }
    .price {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.3px;
    }
    .variant-chooser-quantity,
    .variant-chooser-cta {
      flex: 1 1 100% !important;
    }
  }
}

.btn.btn-primary {
  appearance: none;
  background-color: transparent !important;
  border: $ftborder !important;
  box-sizing: border-box !important;
  color: $basetext;
  font-weight: 700;
  //text-transform: uppercase !important;
  letter-spacing: 1px;
  &:hover {
    //border: rgb(18, 18, 18) 2px solid;
    background-color: $secondary !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $secondary;
  &:hover,
  &:focus {
    color: lighten($secondary, 0.2);
  }
}

.carousel-indicators > .btn {
  padding: 1rem !important;
}

.carousel-indicators > .btn img,
.carousel-indicators > a img {
  filter: brightness(1) !important;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $black;
  color: $white;
  min-height: unset;
  //border-top: 0.1rem solid rgba($basetext, 0.08);
  h5 {
    font-weight: 700;
  }
  a {
    color: $white;
  }
  p,
  li {
    // opacity: 0.8;
  }
}

#product-listing {
  padding-top: 3rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// header image
.header-image {
  position: relative;
  overflow: hidden;

  // @include media-breakpoint-down(sm) {
  //   height: 390px;
  // }

  // img {
  //   width: 100%;
  // }
}

// .header-image::before {
//   background-image: url('./assets/images/background.jpg');
//   content: ' ';
//   filter: blur(0.5rem);
//   background-position: center;
//   background-size: contain;
//   position: fixed;
//   z-index: -10;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   bottom: 0;
//   margin: -1rem;
//   height: auto;
// }

// various
.simple-cart .icon svg {
  // height: 20px !important;

  &:hover {
    transform: scale(1.07) !important;
  }
}
.navbar-light .navbar-nav .nav-link svg.icon {
  color: $basetext;
}

i {
  font-size: 1.2rem;
}

span.item-count {
  background-color: $secondary;
  transform: scale(0.7) translateX(63px);
  color: black !important;
}

span.expiration {
  display: none;
}

// versandart wählen
.checkout {
  .shipping-method-list,
  .payment-method-list {
    label {
      background: none !important;
      color: rgba($basetext, 0.5) !important;
    }
    label.active {
      color: $basetext !important;
      background-color: rgba($secondary, 0.3) !important;
    }
  }
}

// remove borders on tt-listing
.tt-listing {
  th,
  td {
    border-bottom: none !important;
  }
}

// maybe also on modals
.modal {
  .modal-header,
  .sticky-bottom {
  }
}

// TICKETS

.ticket-listing {
  h1 {
    color: $white;
  }

  // .ticket-header-image {
  //   background-image: url('./assets/images/ticket-header-image.jpg');
  //   background-position: center;
  //   background-size: cover;
  //   margin: 0;
  //   padding: 0;
  //   height: 50vh;
  //   height: calc(50vh - 72px);
  //   @include media-breakpoint-up(md) {
  //     height: calc(50vh - 60px);
  //   }
  // }

  .list-page {
    padding-top: 8vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  .nigiri.card {
    //border: 0.15rem solid rgba($basetext, 1);
    border: 1px solid $white;
    border-radius: 0;
    margin-bottom: 1rem !important;
    background-color: transparent;

    //border-bottom: 1px solid rgba($white, 0.25);
    .nigiri-content {
      padding: 0rem;
      color: $white;
      @include media-breakpoint-up(md) {
        align-items: center;
        grid-template-columns: 15rem 2fr auto;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h3-font-size;
      padding: 1.25rem;
      color: $white;
      align-items: center !important;

      // background-image: linear-gradient(to top, black 33%, rgba(255, 255, 255, 0) 0%);
      // background-position: right;
      // background-size: 3px 10px;
      // background-repeat: repeat-y;
      @include media-breakpoint-down(md) {
        background-image: none;
        padding-bottom: 0;
      }
    }

    .nigiri-body {
      font-size: $h2-font-size;
      padding: 1.25rem;
      border-left: 0;
      //text-transform: uppercase;
      font-weight: bold;
      padding-left: 3rem;
      @include media-breakpoint-down(md) {
        padding: 0;
        padding-bottom: 1.25rem;
        margin-bottom: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      font-size: 0.8em;
    }
    .title {
      font-size: $font-size-base * 1.2;
      margin-bottom: 0;
      color: $white;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-base;
      margin-bottom: 0;
      color: $gray-500;
      font-weight: normal;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        //margin-bottom: 0.5rem;
      }
    }
    .location {
      font-weight: 700 !important;
      font-size: $h3-font-size !important;
      font-family: $headings-font-family;
      line-height: .8;
      //margin-top: 4px;
      color: $white;
      @include media-breakpoint-down(md) {
        //margin-bottom: 1rem;
        padding: 0 1.25rem;
      }
    }
    .nigiri-cta {
      padding: 1.25rem;
      // @include media-breakpoint-down(md) {
      //   background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
      //   background-position: top;
      //   background-size: 10px 3px;
      //   background-repeat: repeat-x;
      // }
      .btn.btn-primary {
        border-color: $white !important;
        color: $white;
      }
    }
    &:hover {
      background-color: $white;
      .location, .nigiri-date {
        color: $black !important;
      } 
      .btn.btn-primary {
        border-color: $black !important;
        color: $black;
      }
    }
  }
}

.ticket-detail-page {
  .blurry-background-image {
    //min-height: 50vh;
    background-color: rgba($primary, 1.0);
    box-shadow: none !important;
  }

  .blurry-background-image::before {
    //filter: blur(0.5rem);
    //backdrop-filter: blur(0.5rem);
    background-color: #000 !important;
    opacity: 0.75;
    background-position: center center;
    box-shadow: none !important;
    background-size: 200%;
    //filter: blur(0);
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    border-bottom: 0.1rem solid rgba($basetext, 0.08);

    .super-title {
      font-size: 0.7rem;
      letter-spacing: 1.3px;
      opacity: 1;
    }
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid rgba($basetext, 0.08);
    box-shadow: none;
    i.far {
      color: $secondary !important;
    }
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}

// make category detail look like product listing
body.category-detail-page {
  article.category-detail {
    margin-bottom: 0 !important;
    section.container {
      padding-top: 0 !important;
    }
  }
}

.checkout .when-valid.is-valid,
form .when-valid.is-valid,
.checkout .when-valid,
form .when-valid,
.shoppingcart-finisher {
  background-color: rgba($secondary, 0.9);
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    tbody {
      background-color: $black !important;
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// // STYLING PRODUCT-CONFIG

// body.product-detail-page {
//   .modal-dialog {
//     @include media-breakpoint-up(sm) {
//       max-width: 600px;
//     }
//   }
//   .radio-list-group {
//     box-shadow: none !important;
//   }
//   .kachel-header {
//     background-color: $white;
//     border-color: transparent;
//     &.active {
//       border-color: $secondary;
//     }
//     .media {
//       justify-content: center !important;
//       flex-direction: column;
//       .product-thumb {
//         margin-right: 0 !important;
//         background-color: transparent;
//         @extend .mb-2;
//         width: 50%;
//         height: auto;
//         border-radius: 0;
//         border: 0;
//         box-shadow: none;
//       }
//     }
//     h5 {
//       font-size: 1.2rem !important;
//     }
//     .custom-radio {
//       display: none;
//     }
//   }
//   .kachel-container {
//     display: flex;
//     padding-bottom: 1rem;
//     flex-direction: row;
//     flex-wrap: wrap;
//     justify-content: center;
//     .custom-radio {
//       display: none;
//     }
//     .kachel {
//       img {
//         display: none;
//       }
//       background-color: transparent;
//       display: flex;
//       h5 {
//         font-size: 1.2rem !important;
//       }
//       justify-content: center;
//       height: 4rem;
//       flex: 1 1 20%;
//       //margin: 0.25rem 0;
//       &:hover {
//         background-color: rgba($gray-200, 0.5);
//       }
//       &.active {
//         background-color: $secondary;
//         border-color: $secondary;
//         color: $black;
//         font-weight: 700;
//       }
//     }
//   }
// }
